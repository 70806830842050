import { useContext, useEffect, useState } from "react";
import { StyleContext } from "../context/StyleContextProvider";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Images } from "../common/Images";
import { HomeRounded } from "@mui/icons-material";

export default function MaintenancePage() {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("errorPage")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("errorPage"));
  }, [isMobile]);

  function goHome() {
    window.location.href = "/";
  }

  return (
    <div style={styles.container} className="center-content">
      <div style={styles.content}>
        <Typography variant="h3" sx={styles.heading}>
          Oops!
        </Typography>
        <Typography variant="h5" sx={styles.subheading}>
          Under Maintenance
        </Typography>
        <Button
          fullWidth
          variant="contained"
          sx={styles.goHome}
          endIcon={<HomeRounded />}
          onClick={goHome}
        >
          Go Home
        </Button>
      </div>
      <img style={styles.svg} src={Images.connectionLost} alt="" />
    </div>
  );
}
