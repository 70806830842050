import { apiBaseURL } from "../common/Helper";
import { ResponseModel } from "../models/ResponseModel";

export async function GetMaintenanceStatus() {
  try {
    const response = await fetch(apiBaseURL + "reseller/maintenance-status");
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result: ResponseModel = await response.json();
    return result.response;
  } catch (error) {
    console.error("Error fetching maintenance status:", error);
  }
}
